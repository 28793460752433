<script setup lang="ts">
import { type PropType, ref } from 'vue';
import { useMetaStore } from '~/stores/meta.store';
import { type BlogPost } from '~/types/blog.type'
import type { MetaAttributes } from '~/types/meta-attributes.type';

const props = defineProps({
	data: { type: Object as PropType<BlogPost>, required: true },
	meta: {type: Object as PropType<MetaAttributes>, required: true}
})

const metaStore = useMetaStore();
const meta = ref({});
metaStore.getMetaTags()

meta.value = metaStore.metaGetter();
metaStore.setMeta({...props.meta});
</script>

<template>
	<LayoutAppHead></LayoutAppHead>
	<NuxtLayout name="desktop-default">
		<main>
			<section v-if="data">
				<div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mb-8 mt-8">
					<div class="grid grid-cols-4 gap-4 mx-0">
						<div class="col-span-4">
							<div class="flex flex-col">
								<img v-if="data.picture" class="w-full object-cover rounded-xl" :src="data.picture" :alt="data.title">
								<div class="flex justify-between w-full items-center my-3">
									<h1 class="text-gray-800 text-2xl font-medium mt-2">{{ data.title }}</h1>
								</div>
								<div class="mt-3 text-gray-700">
									<div v-html="data.contents"></div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
		</main>
	</NuxtLayout>
</template>
